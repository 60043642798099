.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: black;
  background: #000;
  position: relative;
  .loading{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: all linear 0.5s;
    &.hideLoading{
      opacity: 0;
    }
  }
}


.animate-spin-slowing {
  animation:spinSlowing 20s cubic-bezier(.29, .37, .38, .44) infinite
}


@keyframes spinSlowing {
  0% {
      transform:rotate(0)
  }
  to {
      transform:rotate(20turn)
  }
}